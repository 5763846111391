<template>
  <getecma-single-content-layout content-class="scroll pe--xxl">
    <template v-if="vehicle" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl" />
      <getecma-row justify="center" class="d-flex align-items--center d-xs-flex-column">
        <getecma-col :xs="24" :span="17" justify="left">
          <getecma-vehicle-view-information :vehicle="vehicle" />
        </getecma-col>
      </getecma-row>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError } from '@/services/toastService';
import { getVehicleById } from '@/modules/vehicle/vehicle.service';
import { MY_SPACE_URL } from '@/modules/my-space/my-space.constants';

import GetecmaVehicleViewInformation from '@/modules/vehicle/components/VehicleViewComponent.vue';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';

export default {
  name: 'GetecmaVehicleViewPage',
  components: {
    GetecmaVehicleViewInformation,
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Veículos', path: MY_SPACE_URL.vehicles.path },
        { name: 'Visualizar', path: this.$route.path },
      ],
      vehicle: null,
      pagination: {
        page: 1,
        limit: 5,
      },
    };
  },
  computed: {
    vehicle_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getVehicleById(this.vehicle_id)
        .then(data => {
          this.vehicle = data;
        })
        .catch(() => toastError('Erro ao obter veículos por ID'));
    },
  },
};
</script>
